@font-face {
  font-family: 'esbuildneutral';
  src: url('../public/fonts/ESBuildNeutral-Regular-EWUS-52.woff2') format('woff2'),
       url('../public/fonts/ESBuildNeutral-Regular-EWUS-52.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'esbuildneutral';
  src: url('../public/fonts/ESBuildNeutral-Bold.woff2') format('woff2'),
       url('../public/fonts/ESBuildNeutral-Bold-EWUS-52.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'esbuildneutral';
  src: url('../public/fonts/ESBuildNeutral-BoldItalic-EWUS-52.woff2') format('woff2'),
       url('../public/fonts/ESBuildNeutral-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'esbuildneutral';
  src: url('../public/fonts/ESBuildNeutral-RegularItalic-EWUS-52.woff2') format('woff2'),
       url('../public/fonts/ESBuildNeutral-RegularItalic-EWUS-52.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
}

body, body * {
  font-family: 'esbuildneutral', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary {
  background-color: #33CC00 !important;
  border-color: #33CC00 !important;
}

.ant-btn-primary:hover {
  background-color: #2EAD00 !important; /* Slightly darker green */
  border-color: #2EAD00 !important; /* Slightly darker green */
}

.ant-btn-primary[disabled] {
  background-color: #b3e6b3 !important; /* Lighter green for disabled state */
  border-color: #b3e6b3 !important; /* Lighter green for disabled state */
  color: #fff !important; /* Text color for disabled state */
}

.ant-btn-default:hover {
  border-color: #33CC00 !important; /* Border color on hover */
  color: #33CC00 !important; /* Font color on hover */
}

.ant-btn-default[disabled] {
  border-color: #d9d9d9 !important; /* Default border color for disabled state */
  color: #d9d9d9 !important; /* Default text color for disabled state */
}

.ant-btn-dangerous:hover {
  background-color: #ff4d4f !important; /* Default danger button color */
  border-color: #ff4d4f !important; /* Default danger button border color */
  color: #fff !important; /* Default text color */
}